import { Fragment, useLayoutEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenRuler, faUserGroup, faCubes, faBugSlash, faRocket } from '@fortawesome/free-solid-svg-icons';
import Footer from "../../UI/Footer";
import Nav from "../../UI/Nav";
import PageTitle from "../../UI/PageTitle";
import ServiceCard from "./ServiceCard";
import ApproachCard from "./ApproachCard";
import ServiceNumber from "./ServiceNumber";
import styles from './Services.module.css';

const Services = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Nav />
      <PageTitle>Services</PageTitle>

      <section className={styles.services}>
        <h4>I'm passionate and skillful in a wide range of essentials, and some nice-to-have's.</h4>

        <div className={styles.serviceCards}>
          <ServiceCard>
            <ServiceNumber>01 /</ServiceNumber>
            <h3>Web Design</h3>
            <p>Attention to detail and knowing your customers are two of the biggest keys when it comes to designing your users' experience and the interface they interact with.</p>
          </ServiceCard>

          <ServiceCard>
          <ServiceNumber>02 /</ServiceNumber>
            <h3>Web Development</h3>
            <p>Developing projects using the latest technologies, making sure sites are optimised for search engines and maximising performance.</p>
          </ServiceCard>

          <ServiceCard>
          <ServiceNumber>03 /</ServiceNumber>
            <h3>Maintenance</h3>
            <p>Pushing projects to CMS systems like WordPress upon completion to make smaller tweaks more accessible.</p>
          </ServiceCard>
        </div>
      </section>

      <section className={styles.approach}>
        <h3 className={styles.approachHeader}>My approach</h3>

        <p className={styles.approachPeak}>A sneak-peak into the process I usually follow when taking on a project.</p>

        <div className={styles.approachCardsContainer}>
          <ApproachCard>
            <FontAwesomeIcon className={styles.approachIcon} icon={faUserGroup} />
            <div className={styles.approachDesc}>
              <ServiceNumber>01 /</ServiceNumber>
              <h3 className={styles.subhead}>Discuss</h3>
              <p>Finding out the end-goal of the project, the essentials and desirables.</p>
            </div>
          </ApproachCard>

          <ApproachCard>
            <FontAwesomeIcon className={styles.approachIcon} icon={faPenRuler} />
            <div className={styles.approachDesc}>
              <ServiceNumber>02 /</ServiceNumber>
              <h3 className={styles.subhead}>Wireframe</h3>
              <p>Drawing up blueprints to showcase the final product. For this I use Adobe XD</p>
            </div>
          </ApproachCard>

          <ApproachCard>
            <FontAwesomeIcon className={styles.approachIcon} icon={faCubes} />
            <div className={styles.approachDesc}>
              <ServiceNumber>03 /</ServiceNumber>
              <h3 className={styles.subhead}>Develop</h3>
              <p>Building time! Transforming the wireframe into a working prototype.</p>
            </div>
          </ApproachCard>

          <ApproachCard>
            <FontAwesomeIcon className={styles.approachIcon} icon={faBugSlash} />
            <div className={styles.approachDesc}>
              <ServiceNumber>04 /</ServiceNumber>
              <h3 className={styles.subhead}>Test</h3>
              <p>Quality control, rigorous testing to identify and resolve any pesky bugs.</p>
            </div>
          </ApproachCard>

          <ApproachCard>
            <FontAwesomeIcon className={styles.approachIcon} icon={faRocket} />
            <div className={styles.approachDesc}>
              <ServiceNumber>05 /</ServiceNumber>
              <h3 className={styles.subhead}>Deploy</h3>
              <p>Launching to the wide world!</p>
            </div>
          </ApproachCard>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Services;