import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import ScreenSwitch from "./ScreenSwitch";
import styles from './Nav.module.css';

const Nav = () => {

  //Toggle navbar handling for mobile devices
  const [mobNavIsVisible, setMobNavIsVisible] = useState(false);

  const mobNavClickHandler = () => {
    setMobNavIsVisible(!mobNavIsVisible);
  };
  
   return (
    <>
      <ScreenSwitch />
      <nav className={styles.desktopNav}>
        <ul>
          <li>
            <Link to={"/"}>HOME</Link>
          </li>
          <li>
            <Link to={"/work"}>WORK</Link>
          </li>
          <li>
            <Link to={"/services"}>SERVICES</Link>
          </li>
          <li>
            <Link to={"/about"}>ABOUT</Link>
          </li>
          <li className={styles.contact}>
            <Link to={"/contact"}>CONTACT</Link>
          </li>
        </ul>
      </nav>

      <button className={styles.navButton} onClick={mobNavClickHandler} type="button">MENU <FontAwesomeIcon className={styles.navIcon} icon={faBars} /></button>

      {mobNavIsVisible && <nav className={styles.mobileNav}>
        <ul>
        <li>
            <Link to={"/"}>HOME</Link>
          </li>
          <li>
            <Link to={"/work"}>WORK</Link>
          </li>
          <li>
            <Link to={"/services"}>SERVICES</Link>
          </li>
          <li>
            <Link to={"/about"}>ABOUT</Link>
          </li>
          <li className={styles.contact}>
            <Link to={"/contact"}>CONTACT</Link>
          </li>
          <li className={styles.mobileNavSocials}>
            <a href="https://github.com/benmassey96" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGithub} /></a>
            <a href="https://www.linkedin.com/in/ben-massey96/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
          </li>
        </ul>
      </nav>}
    </>
  );
};

export default Nav;