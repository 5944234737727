import styles from './ApproachCard.module.css';

const ApproachCard = props => {
  return (
    <div className={styles.approachCard}>
      {props.children}
    </div>
  );
};

export default ApproachCard;