import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Input.module.css';

const TextArea = props => {
  return (
    <div className={`form-floating ${styles.textArea}`}>
      <textarea required className="form-control" placeholder={props.name} id={props.name}></textarea>
      <label className={styles.label} htmlFor={props.name}>Your Message</label>
    </div>
  );
};

export default TextArea;