import styles from './ProjectPills.module.css';

const ProjectPills = props => {
  const technologies = props.tech.map(tech => {
    return <p className={styles.pill}>{tech}</p>
  })

  return (
    <div className={styles.techs}>
      {technologies}
    </div>
  );
};

export default ProjectPills;