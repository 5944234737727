import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import berlin from '../../../images/photography/berlin.jpg';
import budCastle from '../../../images/photography/bud-castle.jpg';
import dubFort from '../../../images/photography/dub-fort.jpg';
import dubKayak from '../../../images/photography/dub-kayak.JPG';
import dubSunset from '../../../images/photography/dub-sunset.jpg';
import fussBuild from '../../../images/photography/fuss-build.jpg';
import fussLand from '../../../images/photography/fuss-land.jpg';
import fussTrees from '../../../images/photography/fuss-tree-walk.jpg';
import londonCab from '../../../images/photography/london-cab.jpg';
import Photo from './Photo';
import styles from './Photography.module.css';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const Photography = () => {
  const photos = [
    {
      id: "p1",
      key: "p1",
      image: dubKayak,
      alt: "Kayak in Dubrovnik"
    },
    {
      id: "p2",
      key: "p2",
      image: dubSunset,
      alt: "Sunset in Dubrovnik"
    },
    {
      id: "p3",
      key: "p3",
      image: budCastle,
      alt: "Budapest Castle"
    },
    {
      id: "p4",
      key: "p4",
      image: fussLand,
      alt: "Fussen Countryside"
    },
    {
      id: "p5",
      key: "p5",
      image: berlin,
      alt: "Berlin"
    },
    {
      id: "p6",
      key: "p6",
      image: fussTrees,
      alt: "Tree-Top Walk in Fussen"
    },
    {
      id: "p7",
      key: "p7",
      image: fussBuild,
      alt: "Fussen Town Centre"
    },
    {
      id: "p8",
      key: "p8",
      image: dubFort,
      alt: "Lokrum Island Fort, Dubrovnik"
    },
    {
      id: "p9",
      key: "p9",
      image: londonCab,
      alt: "London Cab"
    },
  ];

  const photosArr = photos.map(photo => {
    return <Photo 
      id={photo.id}
      key={photo.id}
      image={photo.image}
      alt={photo.alt}
    />
  })

  return (
    <div className={styles.photography}>
      <h3>Photography</h3>

      <p>Aside from programming, I'm also a hobbyist freelance photographer and I've been doing that for 2 years.</p>

      <p>My love of photography started when I started a beer blog on Instagram, and it then progressed to taking pictures for local venues and venues afar. I'm lucky enough to have travelled a bit, so I've seen some sights and I always carry my camera with me.</p>
      
      <div className={styles.photos}>
        {photosArr}
      </div>

      <div className={styles.photoSocials}>
        <a className={styles.photoSocialLink} href='https://www.instagram.com/benmasseyphotos/' rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
        <a className={styles.photoSocialLink} href='https://photography.benmassey.co.uk' rel="noreferrer" target="_blank"><span className={styles.openExtLink}>Visit my site</span> <FontAwesomeIcon icon={faUpRightFromSquare} /></a>
      </div>
    </div>
  );
};

export default Photography;