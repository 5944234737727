import styles from './HomeServiceCard.module.css';

const HomeServiceCard = props => {
  return (
    <div className={styles.homeServiceCard}>
      <h4 className={styles.cardTitle}>{props.title}</h4>
      <p className={styles.desc}>{props.description}</p>
    </div>
  );
};

export default HomeServiceCard;