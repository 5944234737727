import styles from './BenImage.module.css';

const BenImage = props => {
  return (
    <div className={styles.benContainer}>
      <img className={styles.benImg} src={props.image} alt="Ben" />
    </div>
  );
};

export default BenImage;