import cSharp from '../../../images/c-sharp-cert.png';
import fullStack from '../../../images/full-stack-cert.png';
import java from '../../../images/java-cert.png';
import jQuery from '../../../images/jquery-cert.png';
import php from '../../../images/php-cert.png';
import python from '../../../images/python-cert.png';
import microsoft from '../../../images/ms-tech-cert.png';
import styles from './Certifications.module.css';
import Certificate from './Certificate';

const Certifications = () => {
  return (
    <div className={styles.certificationsContainer}>
      <div className={styles.msCert}>
        <h3>Microsoft Certified</h3>
        <Certificate certName="Microsoft Introduction to Programming Using HTML and CSS" img={microsoft} />
      </div>

      <div className={styles.codecademyCerts}>
        <h3>Other certifications</h3>
        <Certificate certName="Full-Stack Engineer" img={fullStack} />
        <Certificate certName="Python 3" img={python} />
        <Certificate certName="PHP" img={php} />
        <Certificate certName="jQuery" img={jQuery} />
        <Certificate certName="Java" img={java} />
        <Certificate certName="C#" img={cSharp} />
      </div>
    </div>
  );
};

export default Certifications;