import styles from './ServiceCard.module.css';

const ServiceCard = props => {
  return (
    <div className={styles.serviceCard}>
      {props.children}
    </div>
  ); 
};

export default ServiceCard;