import styles from './AboutBlob.module.css';

const AboutBlob = () => {
  return (
    <svg className={styles.blob} id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
          <stop id="stop1" stop-color="rgba(182.927, 202.509, 255, 1)" offset="0%"></stop>
          <stop id="stop2" stop-color="rgba(0, 26.094, 255, 1)" offset="100%"></stop>
        </linearGradient>
      </defs>                
      <path fill="url(#sw-gradient)" d="M19.2,-28.9C26.6,-25.1,35.6,-22.7,40.7,-17C45.8,-11.2,47,-2.2,44,5C40.9,12.3,33.7,17.6,27.9,24C22.1,30.5,17.7,38,11.5,40.3C5.4,42.5,-2.7,39.5,-9.8,36.2C-17,32.8,-23.3,29.3,-25.4,23.8C-27.5,18.2,-25.3,10.8,-27.2,3.4C-29,-4,-34.8,-11.2,-34.3,-17C-33.8,-22.8,-26.9,-27,-20.2,-31.2C-13.4,-35.4,-6.7,-39.5,-0.4,-38.8C5.9,-38.2,11.7,-32.8,19.2,-28.9Z" width="100%" height="100%" transform="translate(50 50)" stroke-width="0" style={{transition: "all 0.3s ease 0s"}}></path>
    </svg>
  );
};

export default AboutBlob;