import { Fragment, useLayoutEffect } from "react";
import Nav from "../../UI/Nav";
import PageTitle from "../../UI/PageTitle";
import styles from './Project.module.css';
import imgOne from '../../../images/bmphoto1.png';
import imgTwo from '../../../images/bmphoto2.png';
import imgThree from '../../../images/bmphoto3.png';
import imgFour from '../../../images/bmphoto4.png';
import imgFive from '../../../images/bmphoto5.png';
import bPhoneOne from '../../../images/bmphone1.png';
import bPhoneTwo from '../../../images/bmphone2.png';
import ProjectImages from "./ProjectImages";
import ProjectPills from "../../UI/ProjectPills";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const BMPhoto = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Nav />
      <section>
        <PageTitle>Ben Massey Photography</PageTitle>
        <main className={styles.project}>
          <p className={styles.projectDesc}>
            Photography was my first, and now my second, passion in life. For a while I've been sharing images and gaining some recognition for it, so I thought the time had come to take it to the next level by creating my own website and trying to become professional.

            <br/><br/>

            I wanted to focus on exactly what people would come to my website for: to see my pictures and enquire if they like them. No unnecessary navigation or information. It's still under development whilst I'm an amateur photographer, but I'm proud of how it is right now and I'm excited for it to grow.

            <br/><br/>

            I was also excited to start this project as I put my skills to the test using React.
          </p>

          <ProjectImages images={[imgOne, imgTwo, imgThree, imgFour, imgFive, bPhoneOne, bPhoneTwo]} />

          <div className={styles.deliverables}>
            <h3>Deliverables</h3>
            <p>Develop a website showcasing images, utilising CSS grid layouts, re-usable (functional) React components, providing a contact page and form.</p>

            <ProjectPills tech={['JavaScript', 'jQuery', 'LeafletJS', 'AJAX', 'JSON', 'PHP', 'Bootstrap', 'ReactJS']} />

            <a className={styles.site} href="https://photography.benmassey.co.uk/" target="_blank" rel="noreferrer">Visit site <FontAwesomeIcon icon={faUpRightFromSquare} /></a>
          </div>
        </main>
      </section>
    </>
  );
};

export default BMPhoto;