import { Fragment, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../UI/Footer";
import Nav from "../../UI/Nav";
import PageTitle from "../../UI/PageTitle";
import ProjectTile from "./ProjectTile";
import companydirectory from '../../../images/company-directory.png';
import gazetteer from '../../../images/gazetteer.png';
import styles from './Work.module.css';
import Technologies from "./Technologies";
import Certifications from "./Certifications";
import ben2 from '../../../images/20221213125629_IMG_0732.JPG';
import BenImage from "../../UI/BenImage";
import bmphoto from "../../../images/bmphoto1.png";

const Work = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const projects = [
    {
      id: 1,
      key: 11,
      title: "Company Directory",
      path: "companydirectory",
      image: companydirectory,
      alt: "Company Directory"
    },
    {
      id: 2,
      key: 12,
      title: "Gazetteer",
      path: "gazetteer",
      image: gazetteer,
      alt: "Gazetteer"
    },
    {
      id: 3,
      key: 13,
      title: "Ben Massey Photography",
      path: "benmasseyphotography",
      image: bmphoto,
      alt: "Ben Massey Photography"
    },
    {
      id: 4,
      key: 14,
      title: "Louise Bartle",
      path: "louisebartle",
      image: "",
      alt: ""
    },
    {
      id: 5,
      key: 15,
      title: "Rocket Cafe",
      path: "rocketcafe",
      image: "",
      alt: ""
    }
  ];

  const list_of_projects = projects.map(project => {
    return <Link to={`/${project.path}`}><ProjectTile
        id={project.id}
        key={project.key}
        title={project.title}
        img={project.image}
        alt={project.alt}
      />
    </Link>
  });

  return (
    <>
      <Nav />
      <PageTitle>Work</PageTitle>
      <main className={styles.projects}>
        {list_of_projects}
      </main>

      <BenImage image={ben2} />

      <section className={styles.tech}>
        <h3>Tech stacks</h3>

        <p>Typically I build my projects using the <b><abbr title="Windows, Apache, MySQL, PHP">WAMP</abbr></b> tech stack.</p>

        <p>I started out working with the <b><abbr title="Windows, Apache, MySQL, PHP">WAMP</abbr> stack</b>, but I have been working with the <b><abbr title="MongoDB, Express, React, NodeJS">MERN</abbr> stack</b> more recently to focus on user experience and user interface design.</p>

        <p>Here are some other technologies I work with...</p>

        <Technologies />
        <Certifications />
      </section>
      <Footer />
    </>
  );
};

export default Work;