import { Fragment, useLayoutEffect } from "react";
import Nav from "../../UI/Nav";
import PageTitle from "../../UI/PageTitle";
import styles from './Project.module.css';
import imgOne from '../../../images/gazetteer1.png';
import imgTwo from '../../../images/gazetteer2.png';
import imgThree from '../../../images/gazetteer3.png';
import imgFour from '../../../images/gazetteer4.png';
import imgFive from '../../../images/gazetteer5.png';
import gPhoneOne from '../../../images/gphone1.png';
import gPhoneTwo from '../../../images/gphone2.png';
import ProjectImages from "./ProjectImages";
import ProjectPills from "../../UI/ProjectPills";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const Gazetteer = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Nav />
      <section>
        <PageTitle>Gazetteer</PageTitle>
        <main className={styles.project}>
          <p className={styles.projectDesc}>
            Gazetteer was my first independent, full-stack project. It's a visual, geographical index where the user is able to select a country and the user interface responds to the users interaction.
            Based on the selection from the user, different information is available. The information is available from a number of RESTful APIs, some of which can be toggled into view, such as the weather forecast of the selected country's capital city.
          </p>

          <ProjectImages images={[imgOne, imgTwo, imgThree, imgFour, imgFive, gPhoneOne, gPhoneTwo]} />

          <div className={styles.deliverables}>
            <h3>Deliverables</h3>
            <p>Develop an interactive, responsive user interface that provides a wide range of information including demographic, climatic and geographical data. The application was to make use of a number of third party APIs. A wireframe was first required, outlining the proposed solution.</p>

            <ProjectPills tech={['JavaScript', 'jQuery', 'LeafletJS', 'AJAX', 'JSON', 'PHP', 'Bootstrap']} />

            <a className={styles.site} href="https://gazetteer.benmassey.co.uk/" target="_blank" rel="noreferrer">Visit site <FontAwesomeIcon icon={faUpRightFromSquare} /></a>
          </div>
        </main>
      </section>
    </>
  );
};

export default Gazetteer;