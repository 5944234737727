import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './ContactForm.module.css';
import Input from './Input';
import TextArea from './TextArea';

const ContactForm = () => {
  const submitFormHandler = event => {
    event.preventDefault();

    const formData = {
      firstName: event.target[0].value,
      lastName: event.target[1].value,
      email: event.target[2].value,
      message: event.target[3].value
    };

    $.ajax({
      url: '../../../vendor/mail.php',
      dataType: 'json',
      type: "POST",
      headers: {
          'X-Requested-With' : 'XMLHttpRequest'
      },
      data: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          message: formData.message
      },
      success: function() {
        $('#messageConfirmation').show(400);
      },
      error: function() {
        $('#messageError').show(400);
      }
    });
  };

  return (
    <form className={styles.form} onSubmit={submitFormHandler}>
      <Input name="fName" type="text" placeholder="First Name" />
      <Input name="lName" type="text" placeholder="Last Name" />
      <Input name="email" type="email" placeholder="Email Address" />
      <TextArea name="enquiry" placeholder="Your Message" />

      <button type="submit" className={`${styles.submitContactButton} btn btn-primary`}>Send message</button>

      <p id="messageConfirmation" className={styles.thankYou}>
        <FontAwesomeIcon icon={faCircleCheck} />
      </p>

      <p id="messageError" className={styles.messageError}>
        Oops! It looks like something went wrong. Send me an email at <a href="ben@benmassey.co.uk">ben@benmassey.co.uk</a>.
      </p>      
    </form>
  );
};

export default ContactForm;