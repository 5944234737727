import { Fragment } from 'react';
import ben1 from '../../../images/20221213130709_IMG_0736.JPG';
import styles from './Header.module.css';

const Header = () => {
  return (
    <>
      <header>
        <div className={styles.intro}>
          <h2>Hi, I'm Ben.</h2>
          <h1>Junior Software Engineer and freelance web developer from Hereford.</h1>
        </div>

        <img className={styles.ben} src={ben1} alt="Ben" />
      </header>
    </>
  );
};

export default Header;