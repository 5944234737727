import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer>
      <div className={styles.socials}>
        <a className={styles.socialIcon} href="https://github.com/benmassey96" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a className={styles.socialIcon} href="https://www.linkedin.com/in/ben-massey96/" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>

      <a href="https://benmassey.co.uk">benmassey.co.uk</a>

      <p>Hereford<br/>Herefordshire<br/>United Kingdom</p>
    </footer>
  );
};

export default Footer;