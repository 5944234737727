import { Fragment, useLayoutEffect } from "react";
import Nav from "../../UI/Nav";
import PageTitle from "../../UI/PageTitle";
import styles from './Project.module.css';
import ProjectImages from "./ProjectImages";
import ProjectPills from "../../UI/ProjectPills";
import imgOne from "../../../images/cdirectory1.png";
import imgTwo from "../../../images/cdirectory2.png";
import imgThree from "../../../images/cdirectory3.png";
import imgFour from "../../../images/cdirectory4.png";
import imgFive from "../../../images/cdirectory5.png";
import imgSix from "../../../images/cdirectory6.png";
import imgSeven from "../../../images/cdirectory7.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const CompanyDirectory = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  
  return (
    <>
      <Nav />
      <section>
        <PageTitle>Company Directory</PageTitle>
        <main className={styles.project}>
          <p className={styles.projectDesc}>
            The brief for this Company Directory was to create a CRUD (Create Read Update Delete) application in which the user is able to manage a company's directory of employees, departments and office locations. The application was to be designed mobile-first and work equally as well on a desktop.

            I decided on a phonebook-like design, focusing on the core functionalities for ease-of-use.
          </p>

          <ProjectImages images={[imgOne, imgTwo, imgThree, imgFour, imgFive, imgSix, imgSeven]} />

          <div className={styles.deliverables}>
            <h3>Deliverables</h3>
            <p>A CRUD (Create Read Update Delete) application able to perform operations on a database.</p>

            <ProjectPills tech={['JavaScript', 'jQuery', 'MySQL', 'AJAX', 'JSON', 'PHP', 'Bootstrap', 'XAMPP']} />

            <a className={styles.site} href="https://companydirectory.benmassey.co.uk/" target="_blank" rel="noreferrer">Visit site <FontAwesomeIcon icon={faUpRightFromSquare} /></a>
          </div>
        </main>
      </section>
    </>
  );
};

export default CompanyDirectory;