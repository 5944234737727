import styles from './ProjectTile.module.css';

const ProjectTile = props => {
  return (
    <div className={styles.projectTile}>
      <h3>{props.title}</h3>
      <img src={props.img} alt={props.alt} />
    </div>
  );
};

export default ProjectTile;