import { Fragment, useLayoutEffect } from "react";
import Footer from "../../UI/Footer";
import Nav from "../../UI/Nav";
import PageTitle from "../../UI/PageTitle";
import ContactForm from "./ContactForm";
import styles from './Contact.module.css';

const Contact = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Nav />
      <PageTitle>Contact</PageTitle>
      
      <p className={styles.contactPara}>If you have a project in mind or you'd like to have a chat then please feel free to get in touch.</p>

      <ContactForm />

      <Footer />
    </>
  );
};

export default Contact;