import { useLayoutEffect } from "react";
import HomeServiceCard from "./HomeServiceCard";
import Nav from "../../UI/Nav";
import Header from "./Header";
import Footer from "../../UI/Footer";
import styles from './Home.module.css';
import { Link } from "react-router-dom";

const Home = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={styles.home}>
      <Nav />
      <Header />

      <main>
        <section className={styles.bio}>
          <h3>A budding full-stack developer, specialising in frontend development.</h3>
          <p>End-to-end development. Building bespoke, fluid and responsive projects with an eye for design, handling server-side logic behind the scenes. No project is the same and I thrive off the challenge of taking on new projects and seeing them succeed.</p>
        </section>

        <section className={styles.services}>
          <Link to="/what"><h3>What can I do?</h3></Link>
          <p className={styles.whatDesc}>From single page applications to complex business logic and dealing with databases, having studied full-stack development has enabled me to take on any challenge.</p>

          <div className={styles.homeServices}>
            <HomeServiceCard 
              title="Design"
              description="Wireframe to bring ideas to screen"
            />

            <HomeServiceCard 
              title="Develop"
              description="Take the blueprint and turn it into a working model."
            />

            <HomeServiceCard 
              title="Maintain"
              description="Thinking of the future"
            />
          </div>
        </section>
      </main>

      <Footer />
    </div>
  )
};

export default Home;