import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faCss3, faJs, faPhp, faJava, faPython, faNodeJs, faReact, faBootstrap } from '@fortawesome/free-brands-svg-icons';
import styles from './Technologies.module.css';

const Technologies = () => {
  return (
    <div className={styles.languageContainer}>
      <FontAwesomeIcon className={styles.icon} icon={faHtml5} />
      <FontAwesomeIcon className={styles.icon} icon={faCss3} />
      <FontAwesomeIcon className={styles.icon} icon={faJs} />
      <FontAwesomeIcon className={styles.icon} icon={faPhp} />
      <FontAwesomeIcon className={styles.icon} icon={faJava} />
      <FontAwesomeIcon className={styles.icon} icon={faPython} />
      <FontAwesomeIcon className={styles.icon} icon={faNodeJs} />
      <FontAwesomeIcon className={styles.icon} icon={faReact} />
      <FontAwesomeIcon className={styles.icon} icon={faBootstrap} />
    </div>
  );
};

export default Technologies;