import styles from './ProjectImages.module.css';

const ProjectImages = props => {
  const images = props.images.map(image => {
    return <img src={image} alt="Screenshot taken from projet" className={styles.projectImage} />
  });

  return (
    <div className={styles.projectImages}>
      {images}
    </div>
  );
};

export default ProjectImages;