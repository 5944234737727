import { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Pages/Home/Home';
import Work from './components/Pages/Work/Work';
import Services from './components/Pages/What/Services';
import About from './components/Pages/About/About';
import Contact from './components/Pages/Contact/Contact';
import Gazetteer from "./components/Pages/Work/Gazetteer";
import CompanyDirectory from "./components/Pages/Work/CompanyDirectory";
import BMPhoto from "./components/Pages/Work/BMPhoto";
import './App.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" index element={<Home />}></Route>
          <Route path="/work" element={<Work />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/gazetteer" element={<Gazetteer />}></Route>
          <Route path="/companydirectory" element={<CompanyDirectory />}></Route>
          <Route path="/benmasseyphotography" element={<BMPhoto />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
