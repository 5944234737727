import styles from './Bio.module.css';

const Bio = () => {
  return (
    <div className={styles.bio}>
      <h4>The public sector</h4>
      <p>I've been working in the public sector, administering public-facing software used by over 20,000 users for 4 years now.</p>

      <h4>From administering to creating</h4>
      <p>After a collective 6 years of "looking after" software, my natural curiosity of how things work behind the scenes took a hold and I wanted to really delve deeper into how the web works, so I started to teach myself as we fell into our first national lockdown in 2020.</p>

      <h4>Career Switching - Traineeship</h4>
      <p>In March 2021 I decided to invest in my education and enrolled onto a coding traineeship. I learned and practically applied my learning in languages and frameworks such as PHP, JavaScript and Python.</p>

      <h4>Full-Stack</h4>
      <p>I'm experienced in full-stack development, I love being able to create an entire project myself, writing clean, consistent code. They say you shouldn't have favourites, but I do have a soft-spot for all things frontend</p>

      <h4>Game Development</h4>
      <p>I would consider myself a bit of a gamer, I love a variety of different genres but have a soft-spot for a platformer. Game development is something I'm working on at the moment, among other things.</p>

      <h4>Junior Software Engineer</h4>
      <p>I joined the Met Office as a Junior Software Engineer in February 2023, starting my journey in the industry professionally.</p>
    </div>
  );
};

export default Bio;