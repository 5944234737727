import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Input.module.css';

const Input = props => {
  return (
    <div className={`form-floating mb-3 ${styles.inputContainer}`}>
      <input required type={props.type} className={`form-control ${styles.input}`} id={props.name} name={props.name} placeholder={props.placeholder} />

      <label className={styles.label} htmlFor={props.name}>{props.placeholder}</label>
    </div>
  );
};

export default Input;