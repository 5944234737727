import styles from './Certificate.module.css';

const Certificate = props => {
  return (
    <div className={styles.certificateCard}>
      <p>{props.certName}</p>
      <img className={styles.certImg} src={props.img} alt={props.certName} />
    </div>
  );
};

export default Certificate;