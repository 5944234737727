import { Fragment, useLayoutEffect } from "react";
import ben from '../../../images/ben.png';
import Footer from "../../UI/Footer";
import Nav from "../../UI/Nav";
import PageTitle from "../../UI/PageTitle";
import AboutBlob from './AboutBlob';
import styles from './About.module.css';
import Bio from "./Bio";
import Photography from "./Photography";

const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Nav />
      <PageTitle>About</PageTitle>

      <div className={styles.benContainer}>
        <h3>How's it going?<br/>I'm Ben</h3>
        <AboutBlob />
        <img className={styles.ben} src={ben} alt="Ben"></img>
      </div>

      <Bio />
      <Photography />
      <Footer />
    </>
  );
};

export default About;